import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import islandFormat from '../styles/images/ISLAND.jpg';
import standartFormat from '../styles/images/STANDART.jpg';
import windowFormat from '../styles/images/WINDOW.jpg';

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Navigation } from "swiper";


export class FormatCoffeeShop extends Component {

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {
		const formats = ['STANDART', 'ISLAND', 'WINDOW']

		return (
			<div className='format-shop-block'>
				<h2 className='secondary-title'>Форматы кафе</h2>
					<Swiper
						slidesPerView={1}
						spaceBetween={30}
						pagination={{
							centeredSlides: false,
							clickable: true,
							renderBullet: function (index, className) {
								return '<span class="tab-btn ' + className + '">' + formats[index] + "</span>";
							},
						}}
						centeredSlides={true}
						loop={true}
						className='format-shop-slider form-group'
						navigation={true}
						modules={[Navigation, Pagination]}
					>
					<SwiperSlide>
						<Row>
							<Col lg={7} className='format-image-block'>
								<img alt='Bubble cafe' className='format-image' src={standartFormat} />
							</Col>
							<Col lg={5} className='format-block'>
								<p className='format-title'>Standart Bubble cafe,<br /> 20-40 М2</p>
								<p className='format-invest'>Инвестиции от 1 890 000 &#8381;</p>
								<p>Оборудование: 620 000 &#8381;</p>
								<p>Мебель: от 400 000 &#8381;</p>
								<p>Проектирование: 40 000 &#8381;</p>
								<p>Реклама: 100 000 &#8381;</p>
								<p>СМР: 200 000 &#8381;</p>
								<p>IT Оборудование: 130 000 &#8381;</p>
								<hr />
								<p>Паушальный взнос: 400 000 &#8381;</p>
							</Col>
						</Row>
					</SwiperSlide>
					<SwiperSlide>
						<Row>
							<Col lg={7} className='format-image-block'>
								<img alt='Island cafe' className='format-image' src={islandFormat} />
							</Col>
							<Col lg={5} className='format-block'>
								<p className='format-title'>Island bubble cafe</p>
								<p className='format-invest'>Инвестиции от 1 575 000 &#8381;</p>
								<p>Оборудование: 620 000 &#8381;</p>
								<p>Мебель: 200 000 &#8381;</p>
								<p>Проектирование: 40 000 &#8381;</p>
								<p>Реклама: 100 000 &#8381;</p>
								<p>СМР: 120 000 &#8381;</p>
								<p>IT Оборудование: 95 000 &#8381;</p>
								<hr />
								<p>Паушальный взнос: 400 000 &#8381;</p>
							</Col>
						</Row>
					</SwiperSlide>
					<SwiperSlide>
						<Row>
							<Col lg={7} className='format-image-block'>
								<img alt='Confectionery cafe' className='format-image' src={windowFormat} />
							</Col>
							<Col lg={5} className='format-block'>
								<p className='format-title'>cafe confectionery,<br /> 35-80 М2</p>
								<p className='format-invest'>Инвестиции от 2 600 000 &#8381;</p>
								<p>Оборудование: 900 000 &#8381;</p>
								<p>Мебель: от 550 000 &#8381;</p>
								<p>Проектирование: 40 000 &#8381;</p>
								<p>Реклама: 180 000 &#8381;</p>
								<p>СМР: 350 000 &#8381;</p>
								<p>IT Оборудование: 180 000 &#8381;</p>
								<hr />
								<p>Паушальный взнос: 400 000 &#8381;</p>
							</Col>
						</Row>
					</SwiperSlide>
				</Swiper>
			</div>
		);
	}
}
