import React, { Component } from 'react';
import { Input, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IMaskInput } from 'react-imask';
import { toast } from 'react-toastify';
import './NavMenu.css';

const PhoneMask = '+7 (000) 000-00-00';
//<p>Согласен на обработку <a href="/policy/" target="_blank">персональных данных</a></p>

export class SendForm extends Component {
	static displayName = SendForm.name;

	constructor(props) {
		super(props);

		this.state = {
			name: '',
			phone: '',
			email: '',
			city: '',
			check: false,
			modal: false
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.sendRequest = this.sendRequest.bind(this);
	}

	sendRequest(e) {
		 e.preventDefault();

		 var data = new FormData();

		data.append('name', this.state.name);
		data.append('phone', this.state.phone);
		data.append('email', this.state.email);
		data.append('city', this.state.city);
		data.append('acceptedAgreement', this.state.check);

		const requestOptions = {
			method: 'POST',
			credentials: 'include',
			body: data
		};

		 fetch('api/SampleData/CreateRequest', requestOptions)
			 .then(response => {
				 if (!response.ok) {
					 throw Error(response.statusText);
				 }
				 return Response;
			 })
		 	.then(data => {
				this.setState({
					name: '',
					phone: '',
					email: '',
					city: '',
					check: false
				});
				toast.success('Ваша заявка принята, мы с вами свяжемся');
		 	})
			.catch(error => toast.error('Сервер временно недоступен, мы уже работаем на исправлением, приносим извенения за неудобства'));
	}

	toggleModal(e) {
		this.setState({
			modal: !this.state.modal
		});
	}

	render() {
		return (
			<Form id={'request-form' + this.props.id ? this.props.id : ''} className='request-form-main-block' onSubmit={this.sendRequest}>
				<div className='request-form form-group'>
					<Input
						className='form-control'
						placeholder='Ваше имя'
						value={this.state.name}
						onChange={e => {
							var value = e.target.value;
							this.setState({ name: value })
						}}
						required
					/>
					<IMaskInput
						className='form-control'
						mask={PhoneMask}
						placeholder='+7 (___) __ - __'
						value={this.state.phone}
						onChange={e => {
							var value = e.target.value;
							this.setState({ phone: value })
						}}
						required
					/>
					<Input
						className='form-control'
						type='email'
						placeholder='E-mail'
						value={this.state.email}
						onChange={e => {
							var value = e.target.value;
							this.setState({ email: value })
						}}
						required
					/>
					<Input
						className='form-control'
						placeholder='Ваш город'
						value={this.state.city}
						onChange={e => {
							var value = e.target.value;
							this.setState({ city: value })
						}}
						required
					/>
					<button className='btn btn-primary'>Оставить заявку</button>
				</div>
				<div className='privacy'>
					<span className={'privacy-span' + (this.state.check ? ' checked' : '')}>
						<Input
							className='privacy-input'
							type='checkbox'
							name='privacy'
							required
							checked={this.state.check}
							onChange={this.toggleModal}
						/>
					</span>
					<p className='privacy-text'>Согласен на обработку персональных данных</p>
				</div>

				<Modal isOpen={this.state.modal} toggle={this.toggleModal} className='privacy-modal modal-lg'>
					<ModalHeader><p>Согласие на обработку персональных данных</p></ModalHeader>
					<ModalBody>
						<p>Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных ИП Коваленко Павел Дмитриевич ИНН 761106995637, зарегистрированным в соответствии с законодательством РФ по адресу: улица Мотостроителей, д.77, кв./оф. 75, Ярославская область, р-н Тутаевский, г. Тутаев, Россия (далее по тексту - Оператор).</p>

						<p>1. Согласие дается на обработку одной, нескольких или всех категорий персональных данных, не являющихся специальными или биометрическими, предоставляемых мною, которые могут включать:</p>
						<p>
							- Имя;<br/>
							- Телефон;<br />
							- E-mail;<br />
							- Город.<br />
						</p>

						<p>2. Оператор может совершать следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; блокирование; удаление; уничтожение.</p>
						<p>3. Способы обработки: как с использованием средств автоматизации, так и без их использования.</p>
						<p>4. Цель обработки: предоставление мне услуг/работ, включая, направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ, подготовка и направление ответов на мои запросы, направление в мой адрес информации о мероприятиях/товарах/услугах/работах Оператора.</p>
						<p>5. Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес <a href='mailto:matatafamily@mail.ru'>matatafamily@mail.ru</a> или направления по адресу улица Мотостроителей, д.77, кв./оф. 75, Ярославская область, р-н Тутаевский, г. Тутаев, Россия.</p>
						<p>6. В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без моего согласия при наличии оснований, предусмотренных Федеральным законом №152-ФЗ «О персональных данных» от 27.07.2006 г.</p>
					</ModalBody>
					<ModalFooter>
						<button className='btn btn-primary' onClick={e => { this.setState({ check: true }); this.toggleModal(); }}>Принимаю</button>
						<button className='btn' onClick={e => { this.setState({ check: false }); this.toggleModal(); }}>Не принимаю</button>
					</ModalFooter>
				</Modal>
			</Form>
		);
	}
}
