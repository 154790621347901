import React, { Component } from 'react';
import { Table, Modal, ModalHeader, ModalFooter, Container } from 'reactstrap';
import update from 'immutability-helper';
import { ToastContainer, toast } from 'react-toastify';

export class RequestList extends Component {
	static displayName = RequestList.name;

	constructor(props) {
		super(props);

		this.state = {
			requests: [],
			userModel: {
				login: '',
				password: ''
			},
			showCreateUserModal: false,
			showProcessRequestModal: false,
			showDeleteRequestModal: false,
			requestId: ''
		}

		this.processRequest = this.processRequest.bind(this);
		this.deleteRequest = this.deleteRequest.bind(this);
		this.addUser = this.addUser.bind(this);
	}

	componentDidMount() {
		fetch('api/SampleData/GetRequests')
			.then(response => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then(data => {
				this.setState({ requests: data });
			});
	}

	addUser(e) {
		e.preventDefault();

		var data = new FormData();

		data.append('login', this.state.userModel.login);
		data.append('password', this.state.userModel.password);

		const requestOptions = {
			method: 'POST',
			body: data
		};

		fetch('api/SampleData/CreateUser', requestOptions)
			.then(response => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then(id => {
				this.setState(prevState => update(prevState, {
					userModel: {
						$set: {
							login: '',
							password: ''
						}
					},
					showCreateUserModal: false
				}))
				toast.success('Пользователь добавлен');
			})
			.catch(error => this.setState(prevState => update(prevState, {
				showCreateUserModal: { $set: false }
			})), () => { toast.error('Произошла ошибка при добавлении пользователя') });
	}

	processRequest(e) {
		e.preventDefault();

		var data = new FormData();

		data.append('id', this.state.requestId);

		const requestOptions = {
			method: 'POST',
			body: data
		};

		fetch('api/SampleData/ProcessRequest', requestOptions)
			.then(response => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then(() => {
				let requests = this.state.requests;
				const index = requests.findIndex(x => x.Id === this.state.requestId);
				requests[index].Status = 'Processed';
				this.setState(prevState => update(prevState, {
					requests: { $set: requests },
					requestId: { $set: '' },
					showProcessRequestModal: { $set: false }
				}))
			})
			.catch(error => this.setState(prevState => update(prevState, {
				showProcessRequestModal: { $set: false }
			})), () => { toast.error('Произошла ошибка при удалении заявки') });
	}

	deleteRequest(e) {
		e.preventDefault();

		var data = new FormData();

		data.append('id', this.state.requestId);

		const requestOptions = {
			method: 'POST',
			body: data
		};

		fetch('api/SampleData/DeleteRequest', requestOptions)
			.then(response => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then(() => {
				this.setState(prevState => update(prevState, {
					requests: { $set: this.state.requests.filter(x => x.id !== prevState.requestId) },
					requestId: { $set: '' },
					showDeleteRequestModal: { $set: false }
				}))
			})
			.catch(error => this.setState(prevState => update(prevState, {
				showDeleteRequestModal: { $set: false }
			})), () => { toast.error('Произошла ошибка при обработке заявки') });
	}

	render() {
		return (
			<Container className='admin-page'>
				<ToastContainer />
				<Modal isOpen={this.state.showDeleteRequestModal} className='admin-page-modal'>
					<ModalHeader>Вы действително хотите удалить заявку?</ModalHeader>
					<ModalFooter>
						<button className='btn btn-primary' onClick={this.deleteRequest}>Удалить</button>{' '}
						<button className='btn btn-secondary' onClick={() => this.setState({ showDeleteRequestModal: false })}>Отмена</button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.showProcessRequestModal} className='admin-page-modal'>
					<ModalHeader>Вы действително хотите принять заявку в обработку?</ModalHeader>
					<ModalFooter>
						<button className='btn btn-primary' onClick={this.processRequest}>Принять</button>{' '}
						<button className='btn btn-secondary' onClick={() => this.setState({ showProcessRequestModal: false })}>Отмена</button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.showCreateUserModal} className='admin-page-modal'>
					<ModalHeader>Заполните данные пользователя</ModalHeader>
					<input
						className='form-control'
						type='text'
						placeholder='Введите логин'
						onChange={e => {
							const value = e.target.value;
							this.setState(prevState => update(prevState, { userModel: { login: { $set: value } } }));
						}}
						value={this.state.userModel.login}
					/>
					<input
						className='form-control'
						type='text'
						placeholder='Введите пароль'
						onChange={e => {
							const value = e.target.value;
							this.setState(prevState => update(prevState, { userModel: { password: { $set: value } } }));
						}}
						value={this.state.userModel.password}
					/>
					<ModalFooter>
						<button className='btn btn-primary' onClick={this.createUser}>Добавить пользователя</button>{' '}
						<button className='btn btn-secondary' onClick={() => this.setState({ showCreateUserModal: false })}>Отмена</button>
					</ModalFooter>
				</Modal>
				<h1>Заявки</h1>
				<Table>
					<thead>
						<tr>
							<th>ФИО</th>
							<th>Телефон</th>
							<th>Email</th>
							<th>Город</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{this.state.requests.map((e, i) => {
							return (<tr key={i}>
								<td>{e.name}</td>
								<td>{e.phone}</td>
								<td>{e.email}</td>
								<td>{e.city}</td>
								<td>
									<button
										className='btn btn-secondery'
										onClick={() => e.status === 0 ? this.setState(prevState => update(prevState, { showProcessRequestModal: { $set: true }, requestId: { $set: e.id } })) :
											this.setState(prevState => update(prevState, { showDeleteRequestModal: { $set: true }, requestId: { $set: e.id } })) }
									>
										{e.status === 0 ? 'Отметить как обработанную' : 'Удалить'}
									</button>
								</td>
							</tr>);
						})}
					</tbody>
				</Table>
			</Container>
		);
	}
}
