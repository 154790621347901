export function login(user) {
	window.localStorage['currentUser'] = JSON.stringify({ Login: user.login });
}

export function getCurrentUser() {
	return localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')) : null;
}

export function checkLogin() {
	return fetch('api/SampleData/CheckUser')
		.then(response => {
			if (!response.ok) {
			throw Error(response.statusText);
			}
			return response.json();
		})
		.then(data => {
			return data;
		});
};

export function exitUser() {
	fetch('api/SampleData/ExitUser')
		.then(() => {
			document.cookie = '.CoffeeFranchise=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			window.localStorage.clear();
			window.location.href = '/adminlogin';
		});
};
