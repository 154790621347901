import React from 'react';
import ReactPlayer from 'react-player/lazy';

export class VideoPlayer extends React.Component {
	constructor(props) {
		super(props);
		this.videoRef = React.createRef(props.refName);

		this.state = {
			playing: true,
			muted: true,
			controls: false,
			fullscreenMode: false,
			setFullscreenMode: false
		};

		this.handlePlay = this.handlePlay.bind(this);
	}

	handlePlay = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const video = this.videoRef.current.wrapper.firstChild;

		var el = video;
		if (el.requestFullscreen) {
			el.requestFullscreen();
		} else if (el.msRequestFullscreen) {
			el.msRequestFullscreen();
		} else if (el.mozRequestFullScreen) {
			el.mozRequestFullScreen();
		} else if (el.webkitRequestFullscreen) {
			el.webkitRequestFullscreen();
		}

		this.setState({ playing: true, muted: false, fullscreenMode: !this.state.fullscreenMode, controls: true });
	}

	handleClose = () => {
		const video = this.videoRef.current.wrapper;
		video.webkitExitFullscreen();
	}

	render() {
		return <>
			<ReactPlayer
				ref={this.videoRef}
				url={this.props.src}
				playing={this.state.playing}
				controls={this.state.controls}
				loop={true}
				muted={this.state.muted}
				playsinline={true}
				width='277px'
				height='488px'
				onClick={this.handlePlay}
				className={'video-player ' + this.props.className}
			/>
			<button className='video-fullscreen-close' onClick={this.toggleModal}></button>
		</>
	}
}