import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ReactSlider from 'react-slider';

const checkMarks = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100];
const rental = [100, 160, 220, 280, 340, 400, 460, 520, 580, 640, 700]

export class PriceSlider extends Component {

	constructor(props) {
		super(props);
		this.calculateSums = this.calculateSums.bind(this);

		this.state = this.calculateSums(100, 50);
	}

	calculateSums(revenueValue, rentalValue) {
		const revenueMonth = revenueValue * 550 * 30;
		const prfitSumMonth = ((revenueMonth * 0.6) - (rentalValue * 1000)).toFixed(0);

		return {
			rentalValue,
			revenueValue,
			revenueMonth,
			prfitSumMonth
		};
	}

	render() {
		return (
		<>
			<h2 className='secondary-title'>Рассчитать окупаемость</h2>
			{/* <FormGroup className='tab-block'>
				<button className='tab-btn active'>STANDART</button>
				<button className='tab-btn'>ISLAND</button>
				<button className='tab-btn'>WINDOW</button>
			</FormGroup> */}
			<div className='price-slider'>
				<Row>
					<Col lg={8}>
						<p className='price-slider-title'>количество чеков в день</p>
						<ReactSlider
							marks={checkMarks}
							markClassName='slider-marks'
							className='horizontal-slider'
							min={100}
							max={1100}
							step={100}
							value={this.state.revenueValue}
							thumbClassName='slider-thumb'
							trackClassName='slider-track'
							renderMark={(props) => {
								return <span {...props}>{props.key}</span>
							}}
							onChange={v => this.setState(this.calculateSums(v, this.state.rentalValue))}
						/>
					</Col>
					<Col lg={4} className='price-slider-totals'>
						<p className='price-slider-amount'>{this.state.revenueMonth} &#8381;</p>
						<p className='price-slider-text'>ВЫРУЧКА В МЕСЯЦ</p>
					</Col>
				</Row>
				<Row>
					<Col lg={8}>
						<p className='price-slider-title'>Аренда в месяц, тыс.</p>
						<ReactSlider
							className='horizontal-slider'
							markClassName='slider-marks'
							min={100}
							max={700}
							marks={rental}
							step={60}
							thumbClassName='slider-thumb'
							trackClassName='slider-track'
							renderMark={(props) => {
								return <span {...props}>{props.key}</span>
							}}
							onChange={v => this.setState(this.calculateSums(this.state.revenueValue, v))}
						/>
					</Col>
					<Col lg={4} className='price-slider-totals'>
						<p className='price-slider-amount'>{this.state.prfitSumMonth} &#8381;</p>
						<p className='price-slider-text'>ЧИСТАЯ ПРИБЫЛЬ В МЕСЯЦ</p>
					</Col>
				</Row>
			</div>
		</>
		);
	}
}
