import React, { Component } from 'react';
import { SendForm } from './SendForm';
import { Row, Col, FormGroup, Container } from 'reactstrap';
import { PriceSlider } from './PriceSlider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import { ToastContainer } from 'react-toastify';

import confectionaryShopVideo from '../styles/video/confectionary_shop.mp4';
import educationVideo from '../styles/video/education.mp4';

import { Navigation } from 'swiper';
import logo from '../styles/images/logo_white.svg';
import logoColor from '../styles/images/logo_color.svg';
import scroll from '../styles/icons/scroll.svg';
import cake from '../styles/icons/cake.svg';
import cake2 from '../styles/icons/cake2.svg';
import cakeImage from '../styles/images/cake.png';
import checks from '../styles/icons/checks.svg';
import money from '../styles/icons/money.svg';
import caps from '../styles/icons/caps.svg';
import caps2 from '../styles/icons/caps2.svg';
import partners from '../styles/icons/partners.svg';
import suppliers from '../styles/icons/suppliers.svg';
import graph from '../styles/icons/graph.svg';
import label from '../styles/icons/label.svg';
import tort from '../styles/icons/tort.svg';
import juice from '../styles/icons/juice.svg';
import man from '../styles/images/man.png';
import lemonade from '../styles/icons/lemonade.svg';

import cafe1 from '../styles/images/cafe1.jpg';
import cafe2 from '../styles/images/cafe2.jpg';
import cafe3 from '../styles/images/cafe3.jpg';
import cafe4 from '../styles/images/cafe4.jpg';
import cafe5 from '../styles/images/cafe5.jpg';
import cafe6 from '../styles/images/cafe6.jpg';

import instIcon from '../styles/icons/icon_inst.svg';

import { NavMenu } from './NavMenu';
import { FormatCoffeeShop } from './FormatCoffeShop';
import { VideoPlayer } from './VideoPlayer';


export class Home extends Component {
	static displayName = Home.name;

	handleScrollToElement(event, id) {
		const element = document.getElementById(id);
		window.scrollTo({
			top: element.offsetTop - 103 - 16,
			behavior: 'smooth',
		});
	}

	render() {
		return (
			<>
				<ToastContainer />
				<div className='layout-block'>
					<div className='toolbar' id='toolbar'>
						<Container>
							<Row>
								<Col xs={2}><img alt='логотип' className='toolbar-logo' src={logo} height='71' width='78' /></Col>
								<Col xs={10} className='d-flex'>
									<NavMenu handleScrollToElement={this.handleScrollToElement} />
								</Col>
							</Row>
						</Container>
					</div>
					<Container>
						<div className='main-logo-block' id='main-block'>
							<img alt='логотип' className='main-logo-block-logo' src={logo} height='212' width='228' />
							<Row className='d-flex no-gutters main-title-block'>
								<Col md={6}>
									<h1 className='main-title'>ОТКРОЙ СВОЁ<br />BUBBLE TEA КАФЕ<br />И БУДЬ УНИКАЛЬНЫМ</h1>
								</Col>
								<Col md={6} className='d-flex'>
									<div className='main-logo-block-buttons-block'>
										<button className='btn btn-primary' onClick={e => this.handleScrollToElement(e, 'calculator-block')}>Рассчитать стоимость</button>
										<button className='btn' onClick={e => this.handleScrollToElement(e, 'first-send-form')}>Оставить заявку</button>
									</div>
								</Col>
							</Row>
							<button className='btn-icon mt-auto' onClick={e => this.handleScrollToElement(e, 'realization-block')}>
								<img alt='скролл' src={scroll}/>
							</button>
						</div>

					</Container>
				</div>
				<div className='content-block'>
					<Container id='realization-block'>
						<FormGroup>
							<h2 className='secondary-title'>Мы реализовали</h2>
						</FormGroup>
					</Container>
					<Swiper
						slidesPerView={1}
						spaceBetween={30}
						pagination={{
							clickable: true,
						}}
						centeredSlides={true}
						loop={true}
						className='realization-slider form-group'
						navigation={true}
						modules={[Navigation]}
						breakpoints={{
							1200: {
								slidesPerView: 3
							}
						}}
						autoHeight={false}
					>
						<SwiperSlide className='slide-block'>
							<img alt='торт' className='cake-first' src={cakeImage} />
							<img alt='торт' className='cake-second' src={cakeImage} />
							<span className='number-text'>30 тыс.</span>
							<p className='realization-legend'>BUBBLE TEA</p>
							<div className='realization-legend-active'>
								<p>BUBBLE TEA</p>
							</div>
							<img alt='лимонад' className='carousel-bottom-image' height='115' width='115' src={lemonade} />
						</SwiperSlide>
						<SwiperSlide className='slide-block'>
							<img alt='торт' className='cake-first' src={cakeImage} />
							<img alt='торт' className='cake-second' src={cakeImage} />
							<span className='number-text'>24 тыс.</span>
							<p className='realization-legend'>десертов</p>
							<div className='realization-legend-active'>
								<p>десертов</p>
								<p>собственного производства</p>
							</div>
							<img alt='торт' className='carousel-bottom-image' height='115' width='115' src={cake2} />
						</SwiperSlide>
						<SwiperSlide className='slide-block'>
							<img alt='торт' className='cake-first' src={cakeImage} />
							<img alt='торт' className='cake-second' src={cakeImage} />
							<span className='number-text'>64 тыс.</span>
							<p className='realization-legend'>BUBBLE COFFEE</p>
							<div className='realization-legend-active'>
								<p>BUBBLE COFFEE</p>
							</div>
							<img alt='чашка' className='carousel-bottom-image' height='115' width='115' src={caps2} />
						</SwiperSlide>
						<SwiperSlide className='slide-block'>
							<img alt='торт' className='cake-first' src={cakeImage} />
							<img alt='торт' className='cake-second' src={cakeImage} />
							<span className='number-text'>2568</span>
							<p className='realization-legend'>бенто-тортов</p>
							<div className='realization-legend-active'>
								<p>бенто-тортов</p>
							</div>
							<img alt='торт' className='carousel-bottom-image' height='115' width='115' src={tort} />
						</SwiperSlide>
						<SwiperSlide className='slide-block'>
							<img alt='торт' className='cake-first' src={cakeImage} />
							<img alt='торт' className='cake-second' src={cakeImage} />
							<span className='number-text'>216 кг.</span>
							<p className='realization-legend'>BUBBLE JUICE и тапиоки</p>
							<div className='realization-legend-active'>
								<p>BUBBLE JUICE и тапиоки</p>
							</div>
							<img alt='тапиоки' className='carousel-bottom-image' height='115' width='115' src={juice} />
						</SwiperSlide>
					</Swiper>
				</div>

				<Container>
					<FormGroup id='formats-block' className='content-block'>
						<FormatCoffeeShop />
					</FormGroup>

					<FormGroup className='content-block'>
						<h2 className='secondary-title-small'>Средние продажи в месяц на заведение</h2>
						<div className='sales-block'>
							<div className='sales-item'>
								<p className='sales-amount'>5500</p>
								<span>Напитков</span>
								<img alt='Напитки' className='sales-image' src={caps} height={115} width={115} />
							</div>
							<div className='sales-item'>
								<p className='sales-amount'>2 800 000&#8381;</p>
								<span>Выручка</span>
								<img alt='Выручка' className='sales-image' src={money} height={115} width={115} />
							</div>
							<div className='sales-item'>
								<p className='sales-amount'>4 000</p>
								<span>Чеков</span>
								<img alt='Чеки' className='sales-image' src={checks} height={115} width={115} />
							</div>
						</div>
					</FormGroup>
					<div className='content-block' id='first-send-form'>
						<FormGroup>
							<h2 className='secondary-title-small'>оставить заявку на франшизу</h2>
							<SendForm id='first' />
						</FormGroup>
					</div>
					<div className='content-block' id='franchise-benefits'>
						<FormGroup>
							<Row>
								<Col md={6}><h2 className='secondary-title text-left'>Преимущества франшизы</h2></Col>
								<Col md={6}><p className='secondary-title-description'>Приобретая франшизу MATATA Bubble Tea & Bubble Coffee, вы получите индивидуальный бизнес-проект, который поможет вам достичь желаемых результатов, будь то открытие кафе-кондитерской или создание уютного островка</p></Col>
							</Row>
							<Row>
								<Col>
									<div className='advantages-item'><img alt='партнёры' height={125} width={125} src={partners} /></div>
									<p className='text-center'>Непрерывная поддержка и помощь в продвижении бизнеса</p>
								</Col>
								<Col>
									<div className='advantages-item'><img alt='поставщики' height={125} width={125} src={suppliers} /></div>
									<p className='text-center'>Выгодные цены у проверенных поставщиков</p>
								</Col>
								<Col>
									<div className='advantages-item'><img alt='кондитеры' height={125} width={125} src={cake} /></div>
									<p className='text-center'>Собственные кондитеры, которые поставят вашу десертную матрицу</p>
								</Col>
								<Col>
									<div className='advantages-item'><img alt='встречи' height={125} width={125} src={graph} /></div>
									<p className='text-center'>Персональные встречи с бизнес-партнёрами для разработки стратегии и обучения персонала</p>
								</Col>
								<Col>
									<div className='advantages-item'><img alt='напитки' height={125} width={125} src={label} /></div>
									<p className='text-center'>Регулярное обновление матрицы уникальных напитков</p>
								</Col>
							</Row>
						</FormGroup>
					</div>

					<FormGroup className='content-block' id='calculator-block'>
						<PriceSlider />
						<div className='calculator-text-block'>
							<p className='calculator-text'>Получите финансовую модель бизнеса и узнайте о разных этапах сотрудничества и инвестирования</p>
							<button className='btn btn-primary' type='button' onClick={e => this.handleScrollToElement(e, 'first-send-form')}>Оставить заявку</button>
						</div>
					</FormGroup>

					<FormGroup id='franchise-terms' className='franchise-terms content-block'>
						<Row>
							<Col lg={6}><h2 className='secondary-title text-left'>Условия франшизы</h2></Col>
							<Col className='d-flex' lg={6}>
								<span className='hint-label'>*</span>
								<p className='secondary-title-description'>Отработанная бизнес-модель, обучение стандартам работы и экспертная оценка локаций, а также помощь в их подборе, маркетинговая поддержка</p>
							</Col>
						</Row>
						<Row>
							<Col lg={6} className='form-group conditions-item-block'>
								<div className='conditions-item'>
									<p className='conditions-item-first'>При открытии от 1-го до 3-х заведений:</p>
									<Row>
										<Col xs={6}><p className='conditions-item-amount'>400 000 &#8381;</p></Col>
										<Col xs={6}><p className='conditions-item-percent'>4%</p></Col>
										<Col xs={6}>
											<p className='conditions-item-text'>Единоразовый паушальный взнос за каждое кафе<span className='hint-label'>*</span></p>
										</Col>
										<Col xs={6}><p className='conditions-item-text'>Роялти от валовой выручки, ежемесячно.</p></Col>
									</Row>
								</div>
							</Col>
							<Col lg={6} className='form-group conditions-item-block'>
								<div className='conditions-item'>
									<p className='conditions-item-first'>При открытии более 3-х кафе :</p>
									<Row>
										<Col xs={6}><p className='conditions-item-amount'>300 000 &#8381;</p></Col>
										<Col xs={6}><p className='conditions-item-percent'>3%</p></Col>
										<Col xs={6}>
											<p className='conditions-item-text'>Единоразовый паушальный взнос за каждое кафе<span className='hint-label'>*</span></p>
										</Col>
										<Col xs={6}><p className='conditions-item-text'>Роялти от валовой выручки, ежемесячно.</p></Col>
									</Row>
								</div>
							</Col>
						</Row>
					</FormGroup>

					<FormGroup className='content-block' id='steps-towards-success'>
						<Row>
							<Col md={6}><h2 className='secondary-title text-left'>5 шагов навстречу успеху</h2></Col>
							<Col md={6}><p className='secondary-title-description'>Пришло время проявить свой потенциал и достичь успеха!<br/>Вот пять шагов, которые помогут тебе на этом пути.</p></Col>
						</Row>
						<Row>
							<Col md={6} className='steps-item'>
								<p className='steps-item-number'>1</p>
								<p className='steps-item-text'>Заполни анкету для обсуждения деталей нашего сотрудничества</p>
							</Col>
							<Col md={6} className='steps-item'>
								<p className='steps-item-number'>2</p>
								<p className='steps-item-text'>ПОСЛЕ ПОДПИСИ ДОГОВОРА, мы поможем НАЙТИ ЛОКАЦИЮ ДЛЯ КАФЕ</p>
							</Col>
							<Col md={6} className='steps-item'>
								<p className='steps-item-number'>3</p>
								<p className='steps-item-text'>Внеси паушальный взнос и получи стартовый пакет «Стандарты сети»</p>
							</Col>
							<Col md={6} className='steps-item'>
								<p className='steps-item-number'>4</p>
								<p className='steps-item-text'>Утверждаем дизайн проект и реализуем его</p>
							</Col>
							<Col md={6} className='steps-item'>
								<p className='steps-item-number'>5</p>
								<p className='steps-item-text'>Закупаем оборудование, обучаем персонал и открываем кафе</p>
							</Col>
						</Row>
					</FormGroup>

					<FormGroup className='content-block'>
						<FormGroup>
							<h2 className='secondary-title-small'>оставить заявку на франшизу</h2>
							<SendForm id='second' />
						</FormGroup>
					</FormGroup>

					<FormGroup className='content-block' id='history-matata'>
						<h2 className='secondary-title'>ИСТОРИЯ MATATA FAMILY</h2>
						<Row>
							<Col lg={4} className='d-flex'>
								<img alt='логотип' className='toolbar-logo' src={logoColor} width='234' height='217'/>
							</Col>
							<Col lg={8} className='history-description'>
								<p>Первое BUBBLE TEA CAFE MATATA открылось в Ярославле в Августе 2022 года.</p>
								<p>Основатели сети — опытная команда профессионалов, ранее занимающих ведущие должности в официальных ритейлах крупных компаний.  Благодаря их навыкам и усилиям развивалось множество регионов.</p>
								<p>Мы уверены, что уникальная концепция ярких, свежих и вкусных напитков Bubble Tea,  будет привлекать много гостей и удовлетворять их желания по вкусу и эстетическим требованиям.</p>
								<p>Выбрали ли мы цель? Да! Мы знаем, как добиться результата, при этом делая наших партнёров и всех гостей нашей сети довольными и счастливыми.</p>
							</Col>
						</Row>
					</FormGroup>

					<FormGroup className='content-block' id='history-people'>
						<Swiper
							slidesPerView={1}
							spaceBetween={30}
							centeredSlides={true}
							loop={true}
							className='history-people-slider form-group'
							navigation={false}
							modules={[Navigation]}
						>
							<SwiperSlide>
								<Row>
									<Col className='people-image-block' lg={4}>
										<img alt='фото' className='history-people-image' width={254} height={254} src={man} />
										<p className='history-people-title'>Коваленко Павел</p>
										<p className='history-people-city'>г. Ярославль</p>
										<p className='history-people-counter'>3 BUBBLE TEA CAFE</p>
									</Col>
									<Col lg={8} className='history-people-block'>
										<div className='history-people-description'>
											<p>В августе 2021 года я принял решение об открытии собственной кофейни в городе Ярославль. Когда первые результаты приятно порадовали, я втянулся, и появилась идея открыть ещё одно заведение. Я захотел, чтобы моё заведение предлагало не просто кофе, а стало уникальным местом в городе.</p>
											<p>От желания я перешёл к делу, и в августе 2022 года в Ярославле появилось первое Bubble tea cafe MATATA.</p>
											<p>Вместе с командой мы подготовили карту напитков, подходящих каждому гостю, открыли собственный кондитерский цех и теперь предлагаем десерты собственного производства. Мы добились успеха в городе и получили любовь и поддержку жителей.</p>
											<p>Бренд Matata Family начал развиваться, и сейчас мы находим наши франшизи по всей России. Каждый, кто желает быть уникальным в своём городе, может присоединиться к нам.</p>
										</div>
									</Col>
								</Row>
							</SwiperSlide>
						</Swiper>
					</FormGroup>
				</Container>
				<div className='photo-cafe-slider-block'>
					<div className='img-background'></div>
					<Container>
						<FormGroup className='content-block' id='images-block'>
							<Swiper
								effect='coverflow'
								loop={true}
								slidesPerView={2}
								className='photo-cafe-slider form-group d-none d-lg-block d-lg-block'
								navigation={true}
								centeredSlides={true}
								modules={[Navigation, EffectCoverflow]}
								initialSlide={7}
								coverflowEffect={{
									rotate: 0,
									stretch: 30,
									depth: 100,
									modifier: 5
								}}
							>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe1} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe2} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe3} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe4} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe5} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe6} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe1} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe2} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe3} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe4} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe5} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe6} />
								</SwiperSlide>
							</Swiper>
							<Swiper
								loop={true}
								slidesPerView={1}
								className='photo-cafe-slider-small form-group d-block d-lg-none d-xl-none'
								navigation={true}
								centeredSlides={false}
								modules={[Navigation]}
								initialSlide={1}
							>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe1} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe2} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe3} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe4} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe5} />
								</SwiperSlide>
								<SwiperSlide>
									<img alt='фото' className='photo-cafe-image' src={cafe6} />
								</SwiperSlide>
							</Swiper>
						</FormGroup>
					</Container>
				</div>
				<Container>
					<FormGroup className='content-block' id='specialist-training'>
						<Row>
							<Col md={7}>
								<h2 className='secondary-title-small title-video'>Обучение специалистов</h2>
								<div className='secondary-title-description'>
									<p>Перед тем, как BUBBLE TEA CAFE открывается для посетителей, каждый сотрудник проходит обучение по индивидуальной программе, которая включает в себя как теоретические, так и практические занятия.</p>
									<p>Мы обучим правильному приготовлению и подаче разных видов кофе и BUBBLE TEA по традиционным и авторским уникальным рецептам, расскажем о работе на кассе, подготовим к взаимодействию с гостем, познакомим с оборудованием и уходом за ним.</p>
								</div>
							</Col>
							<Col md={5}>
								<VideoPlayer
									src={educationVideo}
									refName='educationVideo'
									className='ml-auto'
								/>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className='content-block' id='own-confectionery-shop'>
						<Row className='video-block-reverce'>
							<Col md={5}>
								<VideoPlayer
									src={confectionaryShopVideo}
									refName='confectionaryShopVideo'
								/>
							</Col>
							<Col md={7}>
								<h2 className='secondary-title-small title-video'>Собственный кондитерский цех</h2>
								<div className='secondary-title-description'>
									<p>Мы используем только качественные и натуральные ингредиенты для приготовления напитков, а также следим за безопасностью и чистотой в нашем кафе. У нас вы можете заказать напитки как на вынос, так и на месте. Мы также предлагаем услугу доставки по городу.</p>
									<p>BUBBLE TEA CAFE MATATA — это место, где вы сможете насладиться вкусными напитками и провести время в приятной атмосфере в компании друзей и близких. Мы всегда рады видеть вас в нашем кафе!</p>
								</div>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className='content-block contacts-block' id='contacts'>
						<h2 className='secondary-title-small'>Контакты</h2>
						<Row>
							<Col xl={3} className='contacts-value-block'>
								<p className='contacts-title'>Телефон</p>
								<a href='tel:+7(999)233-24-42' className='contacts-value link'>+7 (999) 233 - 24 - 42</a>
							</Col>
							<Col xl={4} className='contacts-value-block'>
								<p className='contacts-title'>e-mail</p>
								<a href='mailto:matatafamily@mail.ru' className='contacts-value link'>matatafamily@mail.ru</a>
							</Col>
							<Col xl={5} className='contacts-value-block'>
								<p className='contacts-title'>Адрес</p>
								<p className='contacts-value'>Г.ЯРОСЛАВЛЬ, УЛ. ПОБЕДЫ 41</p>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup className='content-block contacts-block footer-block'>
						<img alt='логотип' className='footer-block-logo' src={logoColor} width='81' height='75' />
						<div className='footer-options-block'>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'main-block')}>Главная</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'realization-block')}>о франшизе</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'formats-block')}>форматы заведений</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'franchise-benefits')}>преимущества франшизы</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'calculator-block')}>калькулятор окупаемости</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'franchise-terms')}>условия франшизы</button>
						</div>
						<div className='footer-options-block'>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'steps-towards-success')}>5  шагов на встречу успеху </button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'history-matata')}>история matata family</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'specialist-training')}>обучение специалистов</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'own-confectionery-shop')}>собственный кондитерский цех</button>
							<button className='footer-btn' onClick={e => this.handleScrollToElement(e, 'contacts')}>контакты</button>
						</div>
						<div className='footer-contacts-block'>
							<a href='tel:+7(999)233-24-42' className='footer-contacts-value'>+7 (999) 233 - 24 - 42</a>
							<a href='https://instagram.com/matata_yar'><img alt='Инстаграмм' width={24} height={24} src={instIcon} /></a>
						</div>
					</FormGroup>
				</Container>
			</>
		);
	}
}
