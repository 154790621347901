import React, { Component } from 'react';
import { FormGroup, Label, Form, Container } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { login } from './../scripts/sessionHelper';

export class Login extends Component {
	displayName = Login.name;

	constructor(props) {
		super(props);

		this.state = {
			login: '',
			password: ''
		};

		this.login = this.login.bind(this);
	}

	login(e) {
		e.preventDefault();

		var data = new FormData();

		data.append('login', this.state.login);
		data.append('password', this.state.password);

		const requestOptions = {
			method: 'POST',
			credentials: 'include',
			body: data
		};

		fetch('api/SampleData/LoginUser', requestOptions)
			.then(response => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then(data => {
				if (data) {
					login(data);
					window.location.href = '/requests'
				} else {
					toast.error('Произошла ошибка. Логин или пароль не верный.')
				}
			})
			.catch(error => toast.error('Произошла ошибка при попытке войти в систему.'));
	}

	render() {
		return (
			<Container>
				<ToastContainer />
				<Form className='flex container' onSubmit={this.login}>
					<div className='login-form'>
						<h1>Вход</h1>
						<FormGroup>
							<Label>
								Логин:
								<input
									name='login'
									className='form-control'
									type='text'
									value={this.state.login}
									onChange={e => {
										var value = e.target.value;
										this.setState({ login: value })
									}}
								/>
							</Label>
						</FormGroup>
						<FormGroup>
							<Label>
								Пароль:
								<input
									name='pass'
									className='form-control'
									type='password'
									value={this.state.password}
									onChange={e => {
										var value = e.target.value;
										this.setState({ password: value })
									}
									}
								/>
							</Label>
						</FormGroup>
						<button className='btn btn-primary' type='submit'>Войти</button>
					</div>
				</Form>
			</Container>
		);
	}
}