import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { RequestList } from './components/RequestList';
import { Login } from './components/Login';
import { checkLogin } from './scripts/sessionHelper.js';
import update from 'immutability-helper';

import './custom.css'
import './styles/css/index.css';
import './../node_modules/react-toastify/dist/ReactToastify.min.css';

export default class App extends Component {
    static displayName = App.name;

	constructor(props) {
		super(props);
		this.state = {
			isUser: false
		}
	}

	componentDidMount() {
		checkLogin()
			.then(isUser => {
				this.setState(prevState => update(prevState, { isUser: { $set: isUser } }));
			});
	}

	render() {
		const isUser = this.state.isUser;

    return (
      <Layout>
			<Route exact path='/' component={Home} />
			<Route exact path='/requests' component={isUser ? RequestList : Login } />
      </Layout>
    );
  }
}
