import React, { Component } from 'react';
import { NavItem, NavLink, Modal, ModalBody, ModalFooter } from 'reactstrap';
import burger from '../styles/icons/burger.svg';
import instIcon from '../styles/icons/icon_inst.svg';

export class NavMenu extends Component {
	static displayName = NavMenu.name;

	constructor (props) {
		super(props);

		this.toggleModal = this.toggleModal.bind(this);
		this.state = {
			collapsed: false,
			modal: false
		};
	}

	toggleModal(e, goToBlock) {
		this.setState({
			modal: !this.state.modal
		});

		if (goToBlock) {
			this.props.handleScrollToElement(e, goToBlock);
		}
	}


	render () {
		return (
			<>
				<ul className='navbar-nav'>
					<NavItem>
						<NavLink onClick={e => this.props.handleScrollToElement(e, 'realization-block')}>О франшизе</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={e => this.props.handleScrollToElement(e, 'formats-block')}>Форматы кафе</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={e => this.props.handleScrollToElement(e, 'calculator-block')}>Калькулятор окупаемости</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={e => this.props.handleScrollToElement(e, 'franchise-terms')}>Условия франшизы</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={e => this.props.handleScrollToElement(e, 'history-matata')}>О компании</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={e => this.props.handleScrollToElement(e, 'contacts')}>Контакты</NavLink>
					</NavItem>
				</ul>
				<div className='navbar-mobile-block'>
					<button className='btn-icon' onClick={this.toggleModal}>
						<img alt='меню' className='toolbar-logo' src={burger} height='27' width='27' />
					</button>
				</div>

				<Modal isOpen={this.state.modal} toggle={this.toggleModal} className='navbar-modal-block'>
					<ModalBody>
						<button className='modal-close btn-icon' onClick={this.toggleModal}></button>
						<NavLink onClick={e => this.toggleModal(e, 'realization-block')}>О франшизе</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'formats-block')}>Форматы кафе</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'franchise-benefits')}>преимущества франшизы</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'calculator-block')}>Калькулятор окупаемости</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'franchise-terms')}>Условия франшизы</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'steps-towards-success')}>5  шагов на встречу успеху </NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'history-matata')}>история matata family</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'specialist-training')}>обучение специалистов</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'own-confectionery-shop')}>собственный кондитерский цех</NavLink>
						<NavLink onClick={e => this.toggleModal(e, 'contacts')}>контакты</NavLink>
					</ModalBody>
					<ModalFooter>
						<a className='modal-phone-footer' href='tel:+7(999)233-24-42'>+7 (999) 233 - 24 - 42</a>
						<a href='https://instagram.com/matata_yar'><img alt='Инстаграмм' width={24} height={24} src={instIcon} /></a>
					</ModalFooter>
				</Modal>
			</>
		);
	}
}
